import { zipWith } from 'lodash-es';

/**
 * Tag function. Encode all the dynamic values from the associated template literal.
 * Strip out all white spaces and line breaks from the non-dynamic part.
 * Throws an exception if parts of the url that aren't in the dynamic values have unsafe characters.
 * i.e: encodeUrlTag`/api/v1/client/${'123'}/dossier/${}`;
*/
export const encodeUrlTag = (chunks, ...uriComponents) => {
  // Ensure that the parts that are not encoded don't have unsafe characters
  const unsafeCharacters = ['\'', '"', '<', '>', '#', '%', '{', '}', '|', '\\', '^', '~', '[', ']', '`'];
  const isUnsafe = !!chunks.find(
    chunk => unsafeCharacters.find(unsafeChar => chunk.indexOf(unsafeChar) !== -1),
  );
  // Build the url by zipping the two arrays together
  const processedUrl = zipWith(chunks, uriComponents, (chunck, uriComponent) => {
    const encodedUriComponent = (uriComponent !== undefined && uriComponent !== null) ? encodeURIComponent(uriComponent) : '';
    return chunck + encodedUriComponent;
  }).join('');
  // Throw a message if the url has been flagged unsafe
  if (isUnsafe) {
    throw new Error(`Unsafe URL:${processedUrl}`);
  }
  return processedUrl.replace(/\s+/gm, '');
};
