import { useEffect, useState } from 'react';
import { RedirectRequestHandler, AuthorizationNotifier } from '@openid/appauth';

import { NoHashQueryStringUtils } from '../NoHashQueryStringUtils';
import { configure } from '../authFunctions';
import { useVisionplannerDetails } from '../useVisionplannerDetails';
import { cleanAllState, retrieveState } from '../oauthState';

import Layout from './Layout';

const addStatus = (url, status) => {
  const urlObject = new URL(url);
  urlObject.searchParams.delete('status');
  urlObject.searchParams.set('status', status);
  return urlObject.toString();
};

const resumeConfiguration = async ({
  code,
  codeVerifier,
  visionplannerDetails,
}) => configure({
  code,
  codeVerifier,
  clientId: visionplannerDetails.clientId,
}).then(() => 'success', () => 'error');

const ErrorScreen = () =>
  <Layout title="Fout"><p>Er is een onverwachte fout opgetreden. Als dit probleem blijft optreden, neem dan contact op met Visionplanner.</p></Layout>

/**
 * The component that will be mounted on the return URL when authenticating at the VPC
 * authorization server. This component doesn't render a UI, but redirects the user to
 * the application root, and opens VPC in a new tab whenever the authentication flow succeeds.
 */
const VPCAuthorizationCallback = () => {
  const useVisionplannerDetailsQuery = useVisionplannerDetails();
  const authorizationServerUrl = useVisionplannerDetailsQuery?.data?.authorizationServerUrl;

  const [hasError, setError] = useState(false);
  const reportError = (error) => {
    if (error) {
      console.error(error);
    }
    setError(true);
  }

  useEffect(() => {
    if (!authorizationServerUrl) {
      // the URL of the authorization server hasn't been loaded yet.
      return;
    }

    const authorizationHandler = new RedirectRequestHandler(
      undefined,
      new NoHashQueryStringUtils(),
    );

    const notifier = new AuthorizationNotifier();
    notifier.setAuthorizationListener((request, { code, state: correlationId }, error) => {
      if (error) {
        reportError(error);
        return;
      }

      const { code_verifier: codeVerifier } = request.internal;
      const { returnUrl } = retrieveState(correlationId);
      cleanAllState();

      resumeConfiguration({
        code: code,
        codeVerifier,
        visionplannerDetails: useVisionplannerDetailsQuery?.data,
      }).then(status => {
        window.location.href = addStatus(returnUrl, status);
      });
    });
    authorizationHandler.setAuthorizationNotifier(notifier);
    authorizationHandler.completeAuthorizationRequestIfPossible().catch(reportError);
  }, [authorizationServerUrl, useVisionplannerDetailsQuery?.data]);
  
  return hasError ? <ErrorScreen /> : null;
};

export default VPCAuthorizationCallback;
