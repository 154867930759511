import { AuthorizationRequest, AuthorizationServiceConfiguration, FetchRequestor, RedirectRequestHandler } from "@openid/appauth";
import { NoHashQueryStringUtils } from "./NoHashQueryStringUtils";
import { callBackend } from "./portalBackend";
import { storeState } from "./oauthState";

/**
 * This function calls VPAuth to log the user in, based on the id token that is passed. When
 * authentication succeeds, VPAuth stores a cookie, and redirects the browser to the passed
 * redirectUrl. When VPC redirects the browser later to VPAuth to authenticate, VPAuth will
 * see that the user is already logged in, and returns the user to VPC.
 * This function uses the /Portal/Login endpoint, which the portal posts its data to,
 * using navigation, instead of asynchronously (using a fetch call). This is done because
 * the resulting cookies might be blocked by the browser (block third party cookies) when the
 * request would be done asynchronously (using a fetch call)
 */
export const loginWithIdToken = ({
  authorizationServerUrl,
  idToken,
  redirectUrl,
  openInNewWindow,
}) => {
  const form = document.createElement('form');
  form.action = `${authorizationServerUrl}/Portal/Login`;
  form.method = 'POST';
  if (openInNewWindow) {
    form.target = '_blank';
  }
  form.style.visibility = 'hidden';

  const formValues = { idToken, redirectUrl };
  Object.entries(formValues).forEach(([name, value]) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'hidden';
    inputElement.name = name;
    inputElement.value = value;
    form.append(inputElement);
  });

  document.body.append(form);
  form.submit();
};

/**
 * When the user was just authenticated at VPAuth, this function exchanges the authorization
 * code for an identity token at the Demo Integration Portal backend.
 */
export const configure = async ({
  code,
  codeVerifier,
}) => callBackend('/api/RefreshToken/Configure', {
  method: 'POST',
  body: JSON.stringify({
    code,
    code_verifier: codeVerifier,
  }),
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * This function will start OAuth2's authorization code flow with PKCE to let the user authenticate
 * at VPAuth.
 */
export const startVPCAuthentication = async ({ authorizationServerUrl, clientId }) => {
  const serviceConfig = await AuthorizationServiceConfiguration.fetchFromIssuer(
    authorizationServerUrl,
    new FetchRequestor(),
  );

  const authorizationRequest = new AuthorizationRequest({
    client_id: clientId,
    redirect_uri: `${window.location.origin}/vpc-authorization-callback`,
    scope: 'openid offline_access portal_api', // pass offline_access to request a refresh token
    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
    extras: { prompt: 'login' },
    state: storeState({ returnUrl: window.location.href }),
  });

  const authorizationHandler = new RedirectRequestHandler(undefined, new NoHashQueryStringUtils());
  authorizationHandler.performAuthorizationRequest(serviceConfig, authorizationRequest);
};
