const createBackendError = async (response) => {
  if (response.headers.get('content-type')?.includes('application/json')) {
    const errors = await response.json();
    return new Error(errors.join('\n'));
  }

  return new Error(await response.text());
};

export const callBackend = async (...fetchArguments) => {
  const response = await fetch(...fetchArguments);
  if (!response.ok) {
    throw await createBackendError(response);
  }

  if (response.headers.get('content-type')?.includes('application/json')) {
    return response.json();
  }
};

export const startFlow = async (parameters) => callBackend('api/vpcaccess/start-flow', { method: 'POST', body: JSON.stringify(parameters), headers: { 'Content-Type': 'application/json' } });
