import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import LoaderContainer from './components/LoaderContainer';
import Onboarding from './components/Onboarding';
import VisionplannerConnection from './components/VisionplannerConnection';
import VPCAuthorizationCallback from './components/VPCAuthorizationCallback';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <LoaderContainer>
      <Routes>
        <Route index element={<Onboarding />} />
        <Route path="visionplanner-connection" element={<VisionplannerConnection />} />
        <Route path="vpc-authorization-callback" element={<VPCAuthorizationCallback />} />
      </Routes>
    </LoaderContainer>
  </QueryClientProvider>
);

export default App;
