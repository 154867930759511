import { nanoid } from 'nanoid';

const getStateKey = correlationId => `oauthState_${correlationId}`;

export const storeState = (state) => {
  const correlationId = nanoid();
  localStorage.setItem(getStateKey(correlationId), JSON.stringify(state));
  return correlationId;
};

export const cleanAllState = () => {
  Object.keys(localStorage)
    .filter(key => key.startsWith('oauthState_'))
    .forEach(key => {
      localStorage.removeItem(key);
    });
};

export const retrieveState = correlationId => JSON.parse(localStorage.getItem(getStateKey(correlationId)));
