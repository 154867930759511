import styles from './Layout.module.scss';

const Layout = ({ children, title }) => (
  <div className={styles.page}>
    <div className={styles.appbar} />
    <main className={styles.paper}>
      <h2>{title}</h2>
      {children}
    </main>
  </div>
);

export default Layout;