import { createContext, useContext, useMemo, useState } from 'react';

import styles from './LoaderContainer.module.scss';

const LoaderContext = createContext();

const LoaderContainer = ({ children }) => {
  const [usageCount, setUsageCount] = useState(0);
  const loaderContextValue = useMemo(() => ({
    show: () => {
      setUsageCount(current => current + 1);
    },
    hide: () => {
      setUsageCount(current => current - 1);
    },
  }), []);

  return (
    <LoaderContext.Provider value={loaderContextValue}>
      {children}
      {usageCount > 0 ? <div className={styles.loader} /> : null}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);

export default LoaderContainer;
