import { camelCase, isArray, isObject, transform } from 'lodash-es';

/**
 * This recursive function takes an object structure, and returns a deep copy where all property
 * keys have been converted to camelCase.
 */
export const camelize = obj => transform(obj, (acc, value, key, target) => {
  const camelKey = isArray(target) ? key : camelCase(key);

  acc[camelKey] = isObject(value) ? camelize(value) : value;
});
